// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-post-post-template-post-template-js": () => import("../src/components/post/PostTemplate/PostTemplate.js" /* webpackChunkName: "component---src-components-post-post-template-post-template-js" */),
  "component---src-components-tags-tag-template-tag-template-js": () => import("../src/components/tags/TagTemplate/TagTemplate.js" /* webpackChunkName: "component---src-components-tags-tag-template-tag-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tags-js": () => import("../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

